import React from "react"
import { Link } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import Navbar from "../components/navbar"
import Button from "../components/button"
import { FaArrowCircleLeft, FaArrowLeft, FaDownload, FaPencilAlt, FaPencilRuler } from "react-icons/fa"

// Define the function to calculate experience based on the start year
const calculateExperience = (startYear) => {
    const currentYear = new Date().getFullYear(); // Get the current year
    return currentYear - startYear; // Calculate the difference
}

const AboutPage = () => {
    const startYear = 2018; // Define the start year
    const experience = calculateExperience(startYear); // Calculate experience

    return (
      <div style={{ color: `teal`, maxWidth: `800px` }}>
        <title>Scott Murray | About</title>
        <Navbar>
          <Link to="/"><FaArrowLeft /></Link>
        </Navbar>
        <Header headerText="About" />
        <p>I'm a Software Engineer who specializes in Full-Stack Development with <a className="textlink"
                                                                                     href="https://rubyonrails.org/"
                                                                                     target="_blank">Ruby on
          Rails</a> (and Javascript) which I've been working with professionally since 2018 ({experience}+ years exp.), mostly in startup environments.
        </p>
        <p>I've used various flavours of Javascript in every project I've worked on - mostly VueJS, StimulusJS (hurrah
          for <a className="textlink" href="https://hotwired.dev/" target="_blank" rel="noopener noreferrer">Hotwire</a>) along with some Angular and a smidge of React (this site is made with React & generated using <a
            className="textlink" href="https://www.gatsbyjs.org" target="_blank" rel="noopener noreferrer">Gatsby</a>).
        </p>
        <p>Prior to working in software, I studied Mechanical Engineering at the <a
          className="textlink" href="https://www.gla.ac.uk/schools/engineering/" target="_blank"
          rel="noopener noreferrer">University of Glasgow</a> and
          worked in the oil and gas industry in Aberdeen for a few years, overseeing overhaul projects of <a
            className="textlink" href="https://en.wikipedia.org/wiki/Rolls-Royce_RB211" target="_blank"
            rel="noopener noreferrer">Rolls-Royce RB211</a> industrial gas turbines.</p>
        <p>Outside of work, things I care about and spend time on generally revolve around sustainabilty and the environment.  I'm a keen city cyclist and dabbler in environmental stuff like <a className="textlink" target="_blank" href="https://smrry.com/permaculture/">permaculture/rewilding which I sometimes write about here.</a> 🌱🌳</p>
        <p>You can view my CV/résumé below and can get in touch via the social links at the bottom of the page.</p>
        <a href="http://smrry.com/cv.pdf" target="_blank" rel="noopener noreferrer"><Button>CV / Résumé <FaDownload/></Button></a>
        <Link to="/consulting"><Button secondary>Consulting <FaPencilAlt/></Button></Link>
        <Footer />
      </div>
    );
}

export default AboutPage;